@page {
  counter-reset: page 0;
  counter-increment: page 1;
}
@media print {
  @page {
    size: auto;
    margin: 0mm;
    page-break-after: avoid;
    counter-increment: page;
    content: counter(page);
  }
  div.page-number:after {
    /* content: "Page " counter(page) " of " counter(pages) " "; */
    counter-increment: page;
    content: "Page " counter(page) " of " counter(pages) " ";
    margin-right: 10px;
  }

  #pageFooter {
    display: table-footer-group;
  }

  #pageFooter:after {
    counter-increment: page;
    content: counter(page);
  }

  div.page-number {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-family: sans-serif;
    font-size: 1em;
    page-break-after: avoid;
  }

  div.page-footer {
    text-align: center;
    height: 10px;
    font-size: 10px;
    opacity: 0.8;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  div.page-footer p {
    margin: 0;
  }

  div.footer-info,
  div.page-footer {
    display: none;
    height: 30px;
  }

  html {
    margin: 0;
    padding: 0;
    height: auto;
  }

  body {
    position: relative;
    height: auto;
    /* margin-top: 20px !important; */
  }

  /* #pageFooter {
    display: absolute;
    margin-top: 80px;
    float: right;
  }

  #pageFooter:after {
    counter-increment: page;
    content: counter(page) " / " counter(page);
  } */

  a[href]::after {
    content: none !important;
  }
}

.containerBillOfLanding:after {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: url(./asset/logoBg.png) 0 0 repeat;
  background-position: center;
  background-size: 10%;
  opacity: 0.1;
}

.containerBillOfLanding:before {
  content: " ";
  position: fixed;
  left: 0;
  top: 0;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.2;
  /* background-repeat: repeat !important; */
  background-position: center 550px;
  background-size: 100%;
}

.draft:before {
  background-image: url(./asset/draft.png);
  background-position: center 460px !important;
}

.original:before {
  background-image: url(./asset/original.png);
  background-position: center 400px !important;
}
.waybill:before {
  background-image: url(./asset/waybill.png);
  background-position: center 460px !important;
}

.telex:before {
  background-image: url(./asset/telex.png);
  background-position: center 400px !important;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
table,
tr,
td,
th,
div,
p {
  margin: 0;
  padding: 0;
}

.containerBillOfLanding {
  display: table;
  width: 100vw;
  height: 100vh;
  margin: 0 auto !important;
  padding: 0;
}

.containerBillOfLanding .row {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
}

.containerBillOfLanding .rowReverse {
  justify-content: flex-end !important;
}

.block50 {
  width: 50%;
  border: 1px solid #000;
}

.block50Contact {
  width: 50%;
  border: 1px solid #000;
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.block25 {
  width: 25%;
  border: 1px solid #000;
}

.block30 {
  width: 30%;
}

.block40 {
  width: 40%;
}

.block70 {
  width: 70%;
}

.vertical100 {
  height: 100px !important;
}

.vertical90 {
  height: 90px !important;
}

.vertical40 {
  height: 50px !important;
}

.vertical50 {
  height: 50px !important;
}

.vertical60 {
  height: 60px !important;
}

.vertical25 {
  height: 25px !important;
}

.vertical30 {
  height: 30px !important;
}

.vertical35 {
  height: 35px !important;
}

.vertical70 {
  height: 70px !important;
}

.bottomLine {
  border-bottom: 2px solid #000;
}

.borderTop {
  border-top: 1px solid #000;
}

.BorderBottom {
  border-bottom: 1px solid #000;
}

.borderRight {
  border-right: 1px solid #000;
}

.BorderLeft {
  border-left: 1px solid #000;
}

.verticalAlign {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.labelPadding {
  font-size: small;
  font-weight: bold;
  padding-left: 5px;
  padding-top: 5px;
}

.logoPng {
  height: 50px;
}

.blockLogo {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 30px;
  justify-content: space-between;
}

.companyInfo {
  text-align: center;
}

.title {
  text-align: center;
  font-size: large;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 5px;
  margin-bottom: 5px;
}

.slogan {
  text-align: center;
  font-size: x-small;
}

.labelPdfBill {
  margin-left: 5px !important;
  font-size: 12px !important;
  font-weight: bold;
  margin-bottom: 0px !important;
}

.PlaceDelivery {
  font-size: 12px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.PlaceDeliveryValue {
  font-weight: bold;
  margin-left: 20px;
}

.dataInlineLabel {
  display: inline-block !important;
  margin-right: 10px;
}

.dataInline {
  display: inline-block !important;
}

.data {
  font-size: small;
  text-align: center;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  font-weight: bold;
}

.originalStyle {
  background-color: rgb(70, 130, 180);
  color: #fff;
  font-size: 20px !important;
  letter-spacing: 3px !important;
  text-transform: uppercase;
  padding: 2px;
}

.telexStyle {
  color: rgb(107, 175, 230);
  font-family: fantasy;
  letter-spacing: 3px !important;
  font-size: 22px !important;
  text-transform: uppercase;
}

.billOfladingContact {
  margin-top: 0px;
  margin-left: 0px !important;
  padding-left: 5px !important;
  font-size: 10px;
  font-weight: bold;
  list-style: none;
}

.centerData {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  text-align: center;
}

.smallTxt {
  font-size: x-small;
}

.productlistTitle {
  font-size: 16px;
  font-weight: bolder;
  text-align: center;
}

.productlist {
  width: 100%;
  min-height: 250px;
  max-height: 250px;
  border-top: 0;
  border-bottom: 1px solid #000;
  border-left: 2px solid #000;
  border-right: 2px solid #000;
}

.productlistAnnex {
  width: 100%;
  min-height: 250px;
  padding-bottom: 20px;
  border-top: 0;
  border-bottom: 1px solid #000;
  border-left: 2px solid #000;
  border-right: 2px solid #000;
}

.productlistLabelrowContainer {
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #000;
  height: auto;
}

.productlisRowContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
  margin-top: 5px;
}

.productlistLabelrow {
  height: 20px;
  font-size: small;
  font-weight: bold;
}

.productlistLabelrowOrder {
  width: 20%;
  text-align: center;
}

.productlistLabelrowDescription {
  width: 75%;
  padding-left: 5px;
  text-align: left;
}

.productlistLabelrowWeight {
  width: 10%;
  text-align: center;
}

.productlistLabelrowSize {
  width: 15%;
  text-align: center;
}

.productlistrowOrder {
  width: 20% !important;
  text-align: center;
}

.productlistrowDescription {
  width: 75% !important;
  font-size: 12px;
  height: auto;
  margin-left: 5px;
  padding-left: 50px;
  text-align: left;
}

.small {
  font-size: 10px;
}

.productlistrowWeight {
  width: 10% !important;
  text-align: center;
  font-size: 10px;
}

.productlistrowSize {
  width: 15%;
  text-align: center;
  font-size: 10px;
}

.soc {
  height: 10px;
  padding-left: 50px;
  font-size: 14px;
  font-weight: bold;
  align-self: flex-end;
}
.totalInfo {
  height: 20px;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  border: 1px solid #000;
  border-left: 2px solid #000;
  border-right: 2px solid #000;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  font-size: 10px;
}

.footerMsg {
  text-align: center;
  color: #0c1836;
}
