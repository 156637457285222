.MuiList-root a {
  text-decoration: none !important;
}

.mainMenuAccord .elemheaders {
  margin-bottom: 10px;
}

.mainMenuAccord .MuiSvgIcon-root {
  color: #f7af3d;
}

.collapseBtn {
  margin-bottom: 0px;
  margin-top: 10px !important;
}

.collapseBtn p {
  margin-left: 10px;
}

.collapseBtnIcon {
  color: #0c1836 !important;
}
