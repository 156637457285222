body:fullscreen {
  overflow-y: scroll !important;
}
body:-ms-fullscreen {
  overflow-y: scroll !important;
}
body:-webkit-full-screen {
  overflow-y: scroll !important;
}
body:-moz-full-screen {
  overflow-y: scroll !important;
}

.mainZone {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  width: 80%;
  margin: 10px auto;
}

.MuiFormGroup-root {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  float: left;
}

input,
.formSelect {
  min-width: 200px !important;
}

.MuiFormControl-marginNormal {
  margin: 0px;
}

.formGrp {
  flex-wrap: nowrap;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.formGrpClient {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
}

.clientInfoIcon {
  align-self: center;
  margin-right: 20px;
  cursor: pointer;
}

.formGrpPrice {
  flex-wrap: nowrap;
  margin: 0 !important;
  display: flex;
  justify-content: space-around !important;
}

.formGrpPrice input[type="number"] {
  width: 50vw !important;
}

.formGrpPrice input[type="checkbox"] {
  width: 20vw !important;
}

fieldset {
  text-align: left;
  border-top: 1px dotted rgba(119, 119, 119, 0.8);
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  width: 100%;
  padding: 0px 10px !important;
  margin-top: 10px;
}

legend {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 400;
  font-size: large;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}

.containerFieldset {
  border: solid 1px rgb(68, 68, 68) !important;
  padding: 20px 20px 20px 20px;
  border-radius: 20px;
  text-align: center;
  margin-bottom: 30px;
  width: 99% !important;
}

.totalSpan {
  font-size: 18px;
  font-family: emoji;
  font-weight: bold;
}

#cargoTyp .MuiTextField-root {
  width: 50px !important;
  text-align: center !important;
  margin-left: 0px;
}

#cargoType .MuiTextField-root input {
  width: 50px !important;
  text-align: center !important;
  font-size: 23px;
  font-weight: bold;
  padding: 5px 0px 5px 0px;
  margin: 5px 0px 5px 0px;
}

.listContainer {
  width: 100%;
  height: 80% !important;
}

.css-1z0rh7t-MuiStack-root {
  margin-top: 150px !important;
}

.MuiDataGrid-row:nth-child(even) {
  background-color: #ecf0fa;
}

.listEditBtn {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7af3d;
  color: #ecf0fa;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  font-size: 14px;
}

.listEditBtn:hover {
  background-color: #c57b03;
  transition: ease-in;
}

.editBtnIcon {
  font-size: 16px !important;
  margin-right: 5px;
  color: #ecf0fa;
}

.listItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.furnishDescription {
  margin-right: 20px !important;
  width: 400px;
}

.furnishWeight {
  margin-right: 20px !important;
  align-self: flex-start;
}

.furnishQuantity {
  margin-right: 20px !important;
  align-self: flex-start;
}

.contactWidth {
  width: 500px !important;
}

.bookingLink {
  margin-bottom: 20px !important;
  align-self: center;
  margin-left: 20px;
  cursor: pointer;
}

.InputWithLink {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
}

.actionBtnGroup {
  margin-bottom: 30px !important;
  margin-top: 0px !important;
  float: right;
}

.submitBtn {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.MuiTabs-indicator {
  background-color: #f7af3d !important;
  color: #f7af3d;
  padding-top: 5px;
}

/* .MuiTabs-scroller {
  height: 30px !important;
}

.MuiTabs-flexContainer {
  height: 30px !important;
}

.MuiTabs-scroller button {
  height: 30px !important;
} */

.newContactModal {
  position: absolute;
  top: 50%;
  bottom: 50%;
  justify-self: center;
  margin: 0 auto;
  width: 50vw;
  background-color: #fff;
  /* transform: translate(-50%, -50%); */
  box-shadow: 100%;
}

.containertypeModuleRemoveBtn,
.containertypeModuleAddBtn {
  color: #f7af3d;
  font-size: 32px !important;

  cursor: pointer;
}

.containertypeModuleRemoveBtn:hover,
.containertypeModuleAddBtn:hover {
  color: #c57b03 !important;
  border: rgba(119, 119, 119, 0.8) !important;
}

.containertypeModuleRemoveBtn:active,
.containertypeModuleAddBtn:active {
  color: #c57b03 !important;
  border: rgba(119, 119, 119, 0.8) !important;
  border: 2px solid black !important;
  border-radius: 50% !important;
}

.containertypeModuleRemoveBtn {
  margin-right: 5px;
}
.containertypeModuleAddBtn {
  margin-left: 5px;
}

.clientElemContainer {
  display: flex !important;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 5px;
}

.clientElemContainer:hover {
  background-color: rgba(219, 216, 216, 0.8);
}

.clientElemContainer:focus {
  background-color: rgba(219, 216, 216, 0.8);
}

.contactSelectItemsCompany,
.contactSelectItemsName {
  display: flex !important;
  flex-direction: row !important;
  text-transform: capitalize !important;
  padding-left: 10px;
}

.contactSelectItemsCompany p,
.contactSelectItemsName p {
  margin-left: 10px !important;
}

.containersQuantity .MuiInputBase-input {
  color: #000 !important;
}

.voyageListing {
  width: 100% !important;
  border: 1px solid #000 !important;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.voyageListing {
  display: flex !important;
  width: 100% !important;
  flex-direction: column !important;
  flex-wrap: nowrap;
}

.voyageElem {
  background: rgba(203, 203, 203, 0.8) !important;
  width: 100%;
}

.voyageElemModulo {
  background: rgba(224, 222, 222, 0.8) !important;
  width: 100%;
}

.voyageElemModulo:hover,
.voyageElem:hover {
  background-color: rgba(25, 48, 68, 1) !important;
  color: #fff;
  cursor: pointer;
}

.AccordionContainerHeader {
  width: 100%;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.AccordionContainerTitle {
  text-align: left;
  float: left !important;
}

.AccordionContainerElmContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.AccordionContainerImgPreview {
  width: 50px;
  height: 50px;
  border: 1px solid #000;
  float: left;
}

.AccordionContainerImgDetailes th {
  padding-right: 10px !important;
  padding-left: 10px !important;
  border: 1px solid #000 !important;
}

.AccordionContainerImgAction {
  cursor: pointer;
  margin-left: 30px;
  font-size: 30px !important;
  color: #f7af3d;
}

.AccordionContainerImgAction:hover {
  color: #c57b03;
}

#ReactSimpleImageViewer {
  z-index: 99999 !important;
}

.containerDetailsContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
}

.containerTimeLine {
  display: flex;
  justify-content: flex-start;
  width: 35%;
  border-right: 1px solid #000;
  padding-right: 20px;
  padding-left: 10px;
}

.assignedContainerList {
  list-style: none;
}

.assignedContainerList td {
  padding-right: 30px;
}

.assignedContainerList th {
  font-weight: bold;
  text-transform: capitalize;
}

.containerMoreDetailes {
  width: 75%;
}

.operatorContainer {
  width: 100%;
  height: auto;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding-top: 5px;
}

.operatorContainerHeaderBooking {
  height: 30px;
  width: 100%;
  background-color: #c57b03;
}

.operatorContainerHeader {
  height: 30px;
  width: 100%;
  background-color: #0c1836;
}

.bookingSearchSection {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}

.uploadImgsContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.voyageDetailsContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.shipDetailsContainer,
.currentLoadingDetailsContainer {
  width: 45%;
  margin: 0 auto;
}

.shipDetailsContainer td {
  padding-left: 5px;
}

.voyageDetailsContainer table {
  font-size: 14px;
}

.detailsTitle {
  text-align: left;
  margin-bottom: 10px;
}

.pourcentageContainer {
  width: 100%;
  height: 50px;
  background-color: #0c1836;
  color: #fff;
}

.reservedWeightP,
.reservedTeuP {
  height: 50px;
  background-color: #c57b03;
  color: #fff;
  padding-top: 15px;
}

.containerDetailsHistory {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.contactListContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.formGrpClientBol {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
}

.MuiFormHelperText-root {
  text-align: right !important;
  margin-right: 0px !important;
}
