.dropZoneContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 120px !important;
  height: 120px !important;
  border: 2px solid rgb(104, 104, 104);
  margin: 5px 0px;
  border-spacing: 0 !important;
  border-collapse: collapse;
}

.uploadBtn {
  color: aliceblue;
  cursor: pointer;
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding-top: 10px;
}

.btnContainer {
  width: 100%;
  height: 40px;
  margin: 0px;
  outline: 0px;
  background-color: #0c1836;
}
