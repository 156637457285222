html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
table,
tr,
td,
th,
p {
  margin: 0;
}

.bookingPage {
  width: 90vw;
  margin: 20px auto;
  display: table;
}

.bookingHeader {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  width: 70%;
  float: left;
}

h1 {
  font-size: x-large;
  color: #0c1836;
  font-weight: bold;
  text-align: left;
}

h6 {
  color: #0c1836;
}

.qrCode {
  margin-left: 50px;
  width: 40%;
  float: left;
}

.bookingInfo {
  width: 100%;
  color: #0c1836;
  font-weight: bold;
  font-size: 12px;
}

.bookingInfoRight {
  text-align: left;
  width: 35%;
}

.bookingInfoLeft {
  width: 65%;
}

.contactPerson {
  font-weight: 300;
  color: #474747;
}

.bookingDetails {
  width: 100%;
  border: 1px solid #474747;
  margin-bottom: 20px;
  font-size: 10px;
}

.bookingDetails th,
.bookingPrices th {
  text-transform: capitalize;
  text-align: left;
}

.bookingDetails td,
.bookingPrices td {
  text-transform: capitalize;
}

.clientCurrency {
  text-align: right;
  float: right;
}

.bookingDetails tr {
  padding: 2px 0px;
}

.darkLine {
  background-color: rgb(194, 194, 194);
}

.bookingPrices {
  width: 100%;
  border: 1px solid #474747;
  font-size: 10px;
  margin-bottom: 40px;
}

.footerInfo {
  text-align: center;
  color: #0c1836;
  font-weight: bold;
}

.footerMsg {
  text-align: center;
  color: #0c1836;
}
