@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

#logingBody {
  background: #0c1836;
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

#logingBody > .loginSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px !important;
  background-color: rgba(255, 255, 255, 1);
  padding: 50px 20px;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff !important;
}

.loginLogo {
  width: 70%;
  margin-bottom: 30px;
}

#logingBody button {
  border-radius: 20px;
  border: 1px solid #0c1836;
  background-color: #0c1836;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  margin-top: 20px;
  margin-bottom: 20px;
}

.loginCopyright {
  color: #0c1836 !important;
  font-size: 12px !important;
}

#logingBody > button:active {
  transform: scale(0.95);
}

#logingBody button:hover {
  color: #f7af3d !important;
  transition: 0.5s;
}

#logingBody > button:focus {
  outline: none;
}

#logingBody button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

#logingBody .circle {
  position: absolute;
  border-radius: 50%;
  background: #f7af3d;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #f7af3d;
}

#logingBody .small {
  width: 200px;
  height: 200px;
  left: -100px;
  bottom: -100px;
}

#logingBody .medium {
  width: 400px;
  height: 400px;
  left: -200px;
  bottom: -200px;
}

#logingBody .large {
  width: 600px;
  height: 600px;
  left: -300px;
  bottom: -300px;
}

#logingBody .xlarge {
  width: 800px;
  height: 800px;
  left: -400px;
  bottom: -400px;
}

#logingBody .xxlarge {
  width: 1000px;
  height: 1000px;
  left: -500px;
  bottom: -500px;
}

#logingBody .shade1 {
  opacity: 0.2;
}

#logingBody .shade2 {
  opacity: 0.5;
}

#logingBody .shade3 {
  opacity: 0.7;
}

#logingBody .shade4 {
  opacity: 0.8;
}

#logingBody .shade5 {
  opacity: 0.9;
}

@keyframes ripple {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}
